import { react, useState, useRef, useEffect } from 'react';
// import {tracks} from '../../Data/tracks';
//import { tracks as Albums } from '../../Data/tracks';

import DisplayTrack from './DisplayTrack';
import Controls from './Controls';
import ProgressBar from './ProgressBar';

// icons
import {
    IoPlayBackSharp,
    IoPlayForwardSharp,
    IoPlaySkipBackSharp,
    IoPlaySkipForwardSharp,
    IoPlaySharp,
    IoPauseSharp,
} from 'react-icons/io5';

import './AudioPlayer.css';

const AudioPlayer = ({playAlbum}) => {

    // useEffect(() => {
    //     console.log("playAlbum: ", playAlbum);
    //   }, [playAlbum]);

     //console.log("AUDIOPLAYER - playAlbum: ", playAlbum);
 
    const itemEls = useRef(new Array());

    const [trackIndex, setTrackIndex] = useState(0);
    const [currentArtistName, setCurrentArtistName] = useState('Dino Pacifici');
    // const [currentAlbum, setCurrentAlbum] = useState(Albums[0]);
    const [currentAlbum, setCurrentAlbum] = useState(playAlbum);
    const [currentAlbumTracks, setCurrentAlbumTracks] = useState(
        playAlbum?.tracks
    );
    const [currentAlbumTrack, setCurrentAlbumTrack] = useState(
        // Albums[0].tracks[trackIndex]
        playAlbum?.tracks[0]
    );

    useEffect(() => {
        setCurrentAlbum(playAlbum);
        setCurrentAlbumTracks(playAlbum?.tracks);
        setCurrentAlbumTrack(playAlbum?.tracks[0]);
        // console.log("TRACKS: ",playAlbum?.tracks);
    }, [playAlbum]);

    
    // const [currentTrack, setCurrentTrack] = useState(tracks[trackIndex]);
    const [timeProgress, setTimeProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const [elementSelected, setElementSelected] = useState((prevState) => {
        return 'tr' + currentAlbumTrack?.pK_TrackId;
    });

    // reference
    const audioRef = useRef();
    const progressBarRef = useRef();

    const handlePrevious = () => {
        let getTrack = 0;
        if (trackIndex === 0) {
            getTrack = currentAlbumTracks.length - 1;
        } else {
            // setTrackIndex((prev) => prev - 1);
            getTrack = trackIndex - 1;
        }
        selectedTrack(getTrack);
    };

    const handleNext = () => {
        let getTrack = 0;
        if (trackIndex >= currentAlbumTracks?.length - 1) {
            // setTrackIndex(0);
        } else {
            //  setTrackIndex((prev) => prev + 1);
            getTrack = trackIndex + 1;
        }
        selectedTrack(getTrack);
    };

    const selectedTrack = (e) => {
        let dynamicTracks = document.getElementsByClassName('trackWrapper');
        for (let i = 0; i < dynamicTracks.length; i++) {
            dynamicTracks[i].className = 'row trackWrapper g-0 ';
        }
        dynamicTracks[e].className = 'row trackWrapper g-0 selectedTrack';

        setTrackIndex(e);
        setCurrentAlbumTrack(currentAlbumTracks[e]);
        audioRef.src=currentAlbumTracks[e];
    };

    const convertToTime = (convString) => {
        if (convString == undefined){
            return;
        }

        var str = convString.toString().split(':'); // split it at the colons
        var h = str[0];
        var m = str[1];
        var s = str[2];
        var hms = "";

        if (h[1] == "0") {
            h="";
        }
        else {
            h=h[1];
            hms = h + ":"
        }

        if (h == "" ) {
            if (m[0] == "0") {
                m=m[1];
            }
        }

        hms = hms + m +":" + s;
         return hms;
      }


    return (
        <>
            {/* {playAlbum?.buyAts.length >0 
                ? <>
                    <div className="audioPlayerContainerDiv">
                        <div className="audioPlayerContainerSection">
                            {playAlbum.buyAts.map((p, index) =>{
                                return (
                                    <a key={p.pK_BuyAtId} href={p.linkUrl} target="_blank" title={p.linkSiteName + " : " + playAlbum.albumTitle} >
                                        <button type="button">
                                            Click here to purchase {playAlbum.albumTitle} from {p.linkSiteName}
                                        </button>
                                    </a>
                                )
                            })}
                            
                        </div>            
                    </div>
                    </>
                : <>
                    </>
            } */}


            <div className="audioPlayerContainerDivWrapper">
                <div className="container audioPlayerContainerDiv">

                    {/*-- START: First Row*/}
                    <div className="row">
                        
                        {/*-- START: col-5 --*/}
                        <div className="col-lg-5 col-md-5 col-sm-12 p-0">
                            <div className="audioPlayerContainerSection">
                                {/*--START: outer wrapper*/}
                                <div id="musicPlayerWrapper">
                                    <div className="musicPlayer">
                                        <div className="inner">
                                            <DisplayTrack
                                                {...{
                                                    audioRef,
                                                    setDuration,
                                                    progressBarRef,
                                                    handleNext,
                                                    currentAlbum,
                                                    currentAlbumTrack,
                                                    currentArtistName,
                                                    isPlaying,
                                                }}
                                            />
                                            <ProgressBar
                                                {...{
                                                    progressBarRef,
                                                    audioRef,
                                                    timeProgress,
                                                    duration,
                                                }}
                                            />
                                            <Controls
                                                {...{
                                                    audioRef,
                                                    progressBarRef,
                                                    duration,
                                                    setTimeProgress,
                                                    currentAlbumTracks,
                                                    trackIndex,
                                                    setTrackIndex,
                                                    setCurrentAlbumTrack,
                                                    handlePrevious,
                                                    handleNext,
                                                    setIsPlaying,
                                                    isPlaying,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        {/*-- END: col-5 --*/}
            
                        {/*-- START: col-7 --*/}                                          
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            
                            {/*-- START: col-7 row-1 --*/}  
                            <div className="row">
                                <div className="audioPlayerContainerSection">
                                    <div className="trackListingDiv">
                                        {/*--START: outer wrapper*/}
                                        <div key={currentAlbum?.pk_AlbumId} className="container-fluid">
                                            <div className="row">
                                                <div className="col albumTracksDiv">ALBUM TRACKS</div>
                                            </div>

                                            <div className="row">
                                                <div className="container-fluid">
                                                    {Array.isArray(currentAlbumTracks)
                                                        ? currentAlbumTracks.map((t, index) => {
                                                            return (
                                                                <div
                                                                    ref={(element) =>
                                                                        itemEls.current.push(element)
                                                                    }
                                                                    key={'tr' + t.pK_TrackId}
                                                                    id={'tr' + t.pK_TrackId}
                                                                    data-value={index}
                                                                    onClick={() =>
                                                                        selectedTrack(index)
                                                                    }
                                                                    className={`row trackWrapper g-0  ${
                                                                        'tr' + t.pK_TrackId ===
                                                                        elementSelected
                                                                            ? 'selectedTrack'
                                                                            : ' '
                                                                    }`}
                                                                >
                                                                    <div className="col-1 divBorder">
                                                                        {t.trackNumber}:
                                                                    </div>
                                                                    <div className="col-9 divBorder textLeft">
                                                                        {t.trackTitle}
                                                                    </div>
                                                                    <div className="col-2 divBorder textRight">
                                                                        {convertToTime(t.trackLength)}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{' '}
                                {/*-- END: outer wrapper*/}
                            
                            </div>
                            {/*-- END: col-8 row-1 --*/}  
                            
                            {/*-- START: col-8 row-2 --*/} 
                            {/*-- Album Information --*/} 
                            <div className="row">
                                <div className="audioPlayerContainerSection">
                                    <div className="albumInformationDiv">
                                        <div className="albumInformation">ALBUM INFORMATION</div>
                                        {playAlbum?.albumInformation != null 
                                            ? <><div className="text-start">{playAlbum.albumInformation}</div><br/></>
                                            : <></>
                                        }
                                        
                                        {playAlbum?.albumStory != null 
                                            ? <><pre><div className="text-start">{playAlbum.albumStory}</div></pre><br/></>
                                            : <></>
                                        }
                                        {playAlbum?.buyAts.length >0 
                                            ? <>
                                                <div className="text-start">Purchase: &nbsp;  <br/>
                                                    {playAlbum.buyAts.map((p, index) =>{
                                                        return (
                                                            <a key={p.pK_BuyAtId} href={p.linkUrl} target="_blank" title={p.linkSiteName + " : " + playAlbum.albumTitle} >
                                                                <button className="buyMeButton" type="button">
                                                                    Click here to purchase <span >{playAlbum.albumTitle}</span> from {p.linkSiteName}
                                                                </button><br/><br/>
                                                                {/* <button className="buyMeButton" type="button">
                                                                    Click here to purchase<br/><span >{playAlbum.albumTitle}</span><br/> from {p.linkSiteName}
                                                                </button> */}
                                                            </a>
                                                        )
                                                    })}
                                                    
                                                </div>
                                                <br/>
                                                </>
                                            : <>
                                                <div className="text-start">
                                                    Purchase:  This album is not available for purchase at this time. 
                                                </div>
                                                <br/>
                                                </>
                                        }
                                        {playAlbum?.albumGenres != null 
                                            ? <><div className="text-start">Genres: {playAlbum.albumGenres}</div><br/></>
                                            : <></>
                                        }
                                        {/* <div className="row">
                                            <div className="col-6">Copyright &copy;{playAlbum?.albumCopyright} {playAlbum?.albumCopyrightDescription}</div>
                                            <div className="col-6">Length: {convertToTime(playAlbum?.albumLength)}</div>
                                        </div> */}
                                        <div className="text-start">Length: {convertToTime(playAlbum?.albumLength)}</div><br/>
                                        <div className="text-start">Copyright &copy;{playAlbum?.albumCopyright} {playAlbum?.albumCopyrightDescription}</div>
                                    </div>
                                </div>
                            </div>
                            {/*-- END: col-8 row-2 --*/}  
                        
                        </div>
                        {/*-- END: col-8 --*/}     
                    
                    </div>
                    {/*-- END: First Row*/}
                    
                    {/* <div className="row">
                        <div className="col-12">
                            info
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default AudioPlayer;